<template>
  <div>
    <section v-if="layoutInternalCreated" id="about" class="section-content content-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm em Sacadas</h2>

            <p>
              Dentre as mais diversas formas e possibilidades de aplicações de
              insulfilms, estão os
              <strong
                >vidros de sacadas de edifícios comerciais e
                residenciais</strong
              >. Isso ocorre por conta da qualidade e da ampla funcionalidade
              que este material apresenta.
            </p>

            <div @click="index = 1" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <p>
              Quando o assunto é modernidade, elegância, praticidade e
              segurança, os <strong>insulfilmes para sacada</strong> são ideais
              para garantir a
              <strong>prevenção de acidentes, proteção dos móveis </strong>e
              objetos dos raios nocivos à saúde e garante maior redução de calor
              no ambiente.
            </p>

            <p>
              Independente do andar que seu apartamento esteja, é de extrema
              importância a instalação de insulfilm para sacada que
              <strong> reduza a ação do sol </strong> no ambiente, sem tirar as
              características externas da fachada.
            </p>

            <p>
              Conforme Regulamento Interno de cada edifício, em alguns locais,
              não é permitido a modificação da tonalidade dos vidros, e por este
              motivo, existem as <strong>películas transparentes</strong>, que
              reduzem o calor e protegem os móveis e objetos dos raios
              ultravioletas.
            </p>

            <p>
              Nem todo mundo sabe, mas existem insulfilmes para sacada com nano
              partículas de cerâmica (nano híbridas) que podem ajudar nos
              problemas que muitas janelas apresentam, como a alta emissividade
              da luz solar que pode danificar os produtos expostos nelas. O
              efeito dos raios solares é um problema recorrente em sacadas de
              prédios, lojas ou outros estabelecimentos comerciais que expõem
              produtos ao público.
            </p>

            <p>
              Muitos prédios residenciais, lojas e estabelecimentos são afetados
              não apenas pelas altas temperaturas, mas também pelo
              <strong>sol em seus produtos</strong> diariamente, o que pode
              causar todo tipo de dano e até inutilizar itens, como roupas que
              podem perder a cor original devido ao calor e a radiação UV.
            </p>

            <p>
              O mesmo vale para outros produtos como calçados, artigos de couro,
              eletrônicos e até alimentos. Isso sem dúvida levará à
              insatisfação, pois os itens danificados podem causar danos
              consideráveis, ficar inutilizados ou ser vendidos a um preço
              inferior ao normal.
            </p>

            <p>
              Muitos moradores de apartamentos que usam a sacada como uma
              extensão de um cômodo, adotaram o
              <strong>insulfilm transparente para sacadas</strong> com
              nanotecnologia para proteção solar, protegendo móveis e pisos dos
              efeitos nocivos do sol. E é garantido que o insulfilm protetor
              solar tenha um efeito protetor imediatamente após a instalação dos
              equipamentos de medição térmica e UV.
            </p>

            <h3>Como funciona o insulfilm para sacadas?</h3>

            <p>
              O Insulfilm para sacadas com efeito de bloqueio solar, é instalado
              em lojas ou sacadas, o que não só
              <strong>protege os produtos e móveis</strong> expostos, mas também
              ajuda a manter o ar condicionado da loja ou residência,
              <strong>economizando custos de energia</strong>.
            </p>

            <p>
              Os insulfilmes que usamos comprovadamente bloqueiam até 99% dos
              raios ultravioleta (UV) e 90% dos raios infravermelhos, que são as
              principais causas de câncer e outros problemas de pele em todo o
              mundo.
            </p>

            <p>
              Usamos insulfilm importado com garantia de fábrica, e o teste de
              bloco é realizado imediatamente após a instalação para que os
              clientes possam comprovar que nosso filme funciona.
            </p>

            <p>
              Ao utilizar a
              <router-link :to="{ name: 'about' }">MF Film</router-link> como
              seu instalador de insulfilme, você tem garantia do serviço e
              instalação. Aplicamos a melhor película com serviço de qualidade.
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação!</router-link
              >
            </p>

            <h3>Visibilidade do insulfilme para sacadas</h3>

            <p>
              A aplicação do insulfilm com nanotecnologia não altera as
              fachadas, ao contrário, há um conforto visual e
              <strong>transparência</strong> que possibilita sua aplicação em
              condomínios.
            </p>

            <Table class="mb-0-5">
              <tr>
                <td>Transmissão de Luz Visível</td>
                <td class="text-right" width="20%">70%</td>
              </tr>
              <tr>
                <td>Reflexão de Energia Solar</td>
                <td class="text-right" width="20%">9%</td>
              </tr>
              <tr>
                <td>Transmissão de Raios Ultravioletas</td>
                <td class="text-right" width="20%">>1%</td>
              </tr>
              <tr>
                <td>Energia Total Refletida</td>
                <td class="text-right" width="20%">51%</td>
              </tr>
            </Table>

            <p>Cor levemente esverdeada ou azulada</p>

            <h3>Aplicação de insulfilmes para sacadas</h3>

            <p>
              É possível aplicar insulfilmes para sacadas com materiais de
              qualidade e com garantia de fábrica, bloqueando os raios solares,
              protegendo móveis e ajudando a manter a temperatura do ambiente.
            </p>

            <p>
              A <router-link :to="{ name: 'about' }">MF Film</router-link> tem
              mais de 18 anos de experiência com profissionais especializados na
              instalação de insulfilms e garante o serviço completo.
            </p>

            <p>
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação</router-link
              >, fale com um especialista e veja como as películas podem ajudar
              na preservação de bens e economia com ar condicionado.
            </p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");
const Table = () =>
  import(/* webpackChunkName: "Table" */ "@/components/shared/table/table.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
    Table,
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [
        //refletivo
        {
          description:
            "Aplicação de película para proteger os móveis dos raios solares preservando a fachada",
          idLocation: 7,
          src: "/images/fotos/apartamento-rio/pelicula-transparente-sacada-apartamento-2",
          thumb: "/images/fotos/apartamento-rio/small/pelicula-transparente-sacada-apartamento-2",
          title: "Película para sacada",
        },        
      ],
    };
  },

  mounted: function () {
    //console.log(this.photos)
    // console.log('mounted')
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm para Sacadas");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm nas sacadas para proteção, redução de calor, transparência e conforto"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>